import React from "react";
import { Helmet } from 'react-helmet'
import { graphql } from "gatsby"

import Layout from "../components/Layout";
import { Link } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { StaticImage } from 'gatsby-plugin-image'

import Checkmark from "../images/svg/checkmark.svg";

const ApplyNowPageState = {
  applying: 0,
  applied: 1,
  failure: 2
}

const ApplyNow = ({ data }) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [enrollmentState, setEnrollmentState] = React.useState(ApplyNowPageState.applying)
  const [inProcess, setInProcess] = React.useState(false)
  
  return (
    <Layout  renderTrialClass={false} >
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content={data.site.siteMetadata.description} />
      <meta name="keywords" content={data.site.siteMetadata.keywords} />
      <meta property="og:title" content={data.site.siteMetadata.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={data.site.siteMetadata.description} />
      <meta property="og:image" content="" />
      <meta property="og:locale" content="en_us" />
      <meta property="og:site_name" content="Code N Hour" />
      <meta property="og:url" content={`${data.site.siteMetadata.siteUrl}/apply`} />
      <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}/apply`} />
    </Helmet>
      {enrollmentState === ApplyNowPageState.applying && <section className="mt-6">
        <div className="mx-auto">
          <div className="px-8 mx-auto max-w-xl">
            <h1 className="mb-5">Apply Now</h1>
            <form
              className=""
              onSubmit={handleSubmit(async (data) => {
                setInProcess(true)
                const reqBody = {
                  "parent_email": data.parent.email,
                  "parent_first_name": data.parent.name.first,
                  "parent_last_name": data.parent.name.last,
                  "course": data.courseSelector,
                  "student_first_name": data.student.name.first,
                  "student_birth_date": data.student.birthdate,
                  "programming_experience": "Beginner"
                }
                const result = await fetch(`${process.env.GATSBY_CNH_API_BASE_URL}/applynow`, {
                  method: 'POST',
                  headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify(reqBody)
                })
                .then(res => {
                  setEnrollmentState(ApplyNowPageState.applied)
                })
                .catch(e => {
                  console.log("Unable to post request!", e)
                  setEnrollmentState(ApplyNowPageState.failure)
                })
                .finally(() => {
                  setInProcess(false)
                }) 
              })}
            >
              <div className="flex flex-col space-y-6 ">
                <Controller
                  control={control}
                  name="courseSelector"
                  rules={{ required: true }}
                  render={({ field }) => <SelectCourseInput {...field} />}
                />
                {errors.courseSelector?.type === "required" && (
                  <p className="mt-3 text-sm text-red-600">
                    Course is required!
                  </p>
                )}
                <ParentInfoInput register={register} errors={errors} />
                <StudentInfoInput register={register} errors={errors} control={control}/>
              </div>
              {!inProcess && <button
                type="submit"
                className="my-16 inline-flex items-center justify-center px-4 py-2 w-full rounded-md bg-cnh-accent-flamingo hover:bg-cnh-script-500 transition duration-150 ease-in-out text-white font-medium "
                value="Apply" 
              >Apply</button>}
              {inProcess && <button
                type="submit"
                className="my-16 inline-flex items-center justify-center px-4 py-2 w-full rounded-md bg-cnh-accent-flamingo hover:bg-cnh-script-500 transition duration-150 ease-in-out text-white font-medium cursor-not-allowed"
                value="Apply"  
              >
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </button>}
            </form>
          </div>
        </div>
      </section>}
      {enrollmentState === ApplyNowPageState.applied && <section className="mt-10">
                  <div className="mx-auto px-5 mb-10">
                    <div className="px-8 mx-auto max-w-xl flex flex-col items-center mb-5 space-y-5" > 
                      <Checkmark className="w-32 h-32 mb-4 block" />
                      <h2>You are all set! </h2>
                      <p className="text-sm text-slate-600">We'll be contacting you shortly to schedule your child's trial class.</p>
                    </div>         
                    <Link to="/" className="block mx-auto max-w-lg rounded-lg px-5 py-3 text-center font-medium text-white bg-cnh-accent-flamingo hover:bg-cnh-script-500 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out">
                        Home
                    </Link>
                  </div>
        </section>}
      {enrollmentState === ApplyNowPageState.failure && <div>We are embarrased!</div>}
    </Layout>
  );
};


const SelectCourseInput = (props) => {
  const [value, setValue] = React.useState(props.value);
  return (
    <div className="">
      <h3 className="">Select Course</h3>
      <div className="mt-1 flex space-x-4">
        <div
          className="relative flex-1 h-40 rounded-md flex justify-center items-center shadow-md transition duration-150 transform hover:scale-105 bg-cnh-accent-scratch_blue"
          onClick={() => {
            setValue("Block Programming");
            props.onChange("Block Programming");
          }}
        > 

          <div className="w-3/5">
          <StaticImage loading='eager' placeholder='none' src='../images/scratch-logo.png' alt='Selected Scratch course.'/>
                    {value === 'Block Programming' && <Checkmark className="absolute bottom-1 right-1 w-8 h-8" />}
          </div>
          
        </div>
        <div
          className="flex-1 h-40 rounded-md flex justify-center items-center shadow-md transition duration-150 transform hover:scale-105 bg-cnh-accent-web_yellow"
          onClick={() => {
            setValue("Web Development");
            props.onChange("Web Development");
          }}
        >
          <div className="w-3/5">
          <StaticImage loading='eager' placeholder='none' src='../images/web-programming-logo.png' alt='Learn Web Programming course.'/>
          
          {value === 'Web Development' && <Checkmark className="absolute bottom-1 right-1 w-8 h-8" />}

          </div>
        </div>
        <div
          className="flex-1 h-40 rounded-md flex justify-center items-center shadow-md transition duration-150 transform hover:scale-105 bg-cnh-accent-python_blue"
          onClick={() => {
            setValue("Python");
            props.onChange("Python");
          }}
        >
          <div className="w-3/5">
          <StaticImage loading='eager' placeholder='none' src='../images/python-logo.png' alt='Learn Python course.'/>
          {value === 'Python' && <Checkmark className="absolute bottom-1 right-1 w-8 h-8" />}

          </div>
        </div>
      </div>
    </div>
  );
};

const ParentInfoInput = ({ register, errors }) => {
  return (
    <div className="">
      <h3 className="mb-2">Parent Information</h3>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <label className="mb-1 flex flex-col md:flex-row md:items-center">
            <span className="block w-48  text-gray-700">First Name</span>

            <input
              {...register("parent.name.first", {
                required: true,
                pattern: /^[a-zA-Z0-9]+$/i,
              })}
              type="text"
              className="mt-1 w-full block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Charles"
              autocomplete="off"
            />
          </label>
          {errors.parent?.name?.first?.type === "required" && (
            <p className="text-sm text-red-600">First name is required!</p>
          )}
          {errors.parent?.name?.first?.type === "pattern" && (
            <p className="text-sm text-red-600">
              Only alphanumeric characters allowed!
            </p>
          )}
        </div>
        <div className="flex-1">
          <label className="mb-1 flex flex-col md:flex-row md:items-center">
            <span className="w-48 text-gray-700">Last Name</span>
            <input
              {...register("parent.name.last", {
                required: true,
                pattern: /^[a-zA-Z0-9]+$/i,
              })}
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Babbage"
              autocomplete="off"
            />
          </label>
          {errors.parent?.name?.last?.type === "required" && (
            <p className="text-sm text-red-600">Last name is required!</p>
          )}
          {errors.parent?.name?.last?.type === "pattern" && (
            <p className="text-sm text-red-600">
              Only alphanumeric characters allowed!
            </p>
          )}
        </div>
        
        <div className="flex-1">
          <label className="mb-1 flex flex-col md:flex-row md:items-center">
            <span className="w-48  text-gray-700">Email</span>
            <input
              {...register("parent.email", {
                required: true,
                pattern:
                  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
              })}
              type="email"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="charles.babbage@codenhour.com"
              autocomplete="off"
            />
          </label>
          {errors.parent?.email?.type === "required" && (
            <p className="text-sm text-red-600">Email is required!</p>
          )}
          {errors.parent?.email?.type === "pattern" && (
            <p className="text-sm text-red-600">Not a valid email address!</p>
          )}
        </div>
      </div>
    </div>
  );
};

const StudentInfoInput = ({ register, errors, control }) => {
  return (
    <div className="">
      <h3 className="mb-2">Student Information</h3>
      <div className="grid grid-cols-1 gap-6">
        <div>
          <label className="mb-1 flex flex-col md:flex-row md:items-center">
            <span className="block w-48  text-gray-700">First Name</span>

            <input
              {...register("student.name.first", {
                required: true,
                pattern: /^[a-zA-Z0-9]+$/i,
              })}
              type="text"
              className="mt-1 w-full block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Benjamin"
              autocomplete="off"
            />
          </label>
          {errors.student?.name?.first?.type === "required" && (
            <p className="text-sm text-red-600">First name is required!</p>
          )}
          {errors.student?.name?.first?.type === "pattern" && (
            <p className="text-sm text-red-600">
              Only alphanumeric characters allowed!
            </p>
          )}
        </div>
        
        <div className="flex-1">
          <label className="mb-1 flex flex-col md:flex-row md:items-center">
            <span className="w-48  text-gray-700">Date of Birth</span>
            <input
              {...register("student.birthdate", {
                required: true,
              })}
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="mm/dd/yyyy"
              autocomplete="off"
            />
          </label>
          {errors.student?.birthdate?.type === "required" && (
            <p className="text-sm text-red-600">Date of Birth is required!</p>
          )}
          {errors.student?.birthdate?.type === "pattern" && (
            <p className="text-sm text-red-600">Not a valid date!</p>
          )}
        </div>
        <div>
          <Controller
                    control={control}
                    name="student.programmingSkillLevel"
                    rules={{ required: true }}
                    render={({ field }) => <ProgrammingSkillLevel {...field} />}
                  />
          {errors.student?.programmingSkillLevel?.type === "required" && (
            <p className="mt-3 text-sm text-red-600">
              Previous Programming Skill is required!
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const ProgrammingSkillLevel = (props) => {
  const [value, setValue] = React.useState(props.value);
  const limited = 'limited'
  const none = 'none'
  return (
      <div className="flex-1">
        <div className="w-full mb-2  text-gray-700">
          Previous Programming Experience
        </div>
        <div className="mt-1 grid grid-cols-2 gap-3">
          <div className="flex-1 border border-gray-400 shadow-sm rounded-md flex items-center justify-center transition duration-150 transform hover:scale-105"
          onClick={() => {
            setValue(none);
            props.onChange(none);
          }}>
            <div className="flex-col flex px-2 py-6 space-y-1">
              <h4 className="text-medium text-center">None</h4>
              <p className="text-sm"></p>
            </div>
            {value === 'none' && <Checkmark className="absolute bottom-1 right-1 w-8 h-8" />}
          </div>
          <div className="flex-1 border border-gray-400 shadow-sm rounded-md flex items-center justify-center transition duration-150 transform hover:scale-105"
          onClick={() => {
            setValue(limited);
            props.onChange(limited);
          }}>
            <div className="flex-col flex px-2 py-6 space-y-1">
              <h4 className="text-medium text-center">Limited</h4>
              <p className="text-sm"></p>
            </div>
            {value === limited && <Checkmark className="absolute bottom-1 right-1 w-8 h-8" />}
          </div>
        </div>
      </div>
  )
}

export default ApplyNow;

export const query = graphql`
{
  site {
    siteMetadata {
      description
      keywords
      siteUrl
      title
    }
  }
}
`;

